import React from 'react'
//import { Link } from 'gatsby'
import Layout from '../components/layout'
import dreamV from '../images/The_Dream_-_IV_Play_[Music_Video].mp4'
import dream from '../images/The_Dream_-_IV_Play_[Music_Video]-261.jpg'
import JimmyReel from '../images/JIMMY_2014-004.jpg'
import JimmyReelV from '../images/jIMMY_2014.mp4'
import medicalreel from '../images/medicalreel_jm.jpg'
import medicalreelV from '../images/medicalreel_jm.mp4'
import history from '../images/History_Channel_3D.jpg'
import historyV from '../images/History_Channel_3D.mp4'
import Elefun from '../images/Elefun_and_Friends.jpg'
import ElefunV from '../images/Elefun_and_Friends.mp4'
import firstdata from '../images/First_Data_Graphics_Reel.jpg'
import firstdataV from '../images/First_Data_Graphics_Reel.mp4'
import lovesigns from '../images/Love_Signs_-_GFX.jpg'
import lovesignsV from '../images/Love_Signs_-_GFX.mp4'
import Kreo from '../images/Kreo_Battleship_Mini_Movie.jpg'
import KreoV from '../images/Kreo_Battleship_Mini_Movie.mp4'
import coke from '../images/Coke_Pay_It_Forward.jpg'
import cokeV from '../images/Coke_Pay_It_Forward.mp4'
import nerf from '../images/NERF_Light_it_Up.jpg'
import nerfV from '../images/NERF_Light_it_Up.mp4'
import verizon from '../images/NetSup_Verizon.jpg'
import verizonV from '../images/NetSup_Verizon.mp4'
import ION from '../images/ION4.jpg'
import IONV from '../images/ION4.mp4'
import NEWBRAND from '../images/Verizon_New_Brand_Module_Excerpt.jpg'
import NEWBRANDV from '../images/Verizon_New_Brand_Module_Excerpt.mp4'
import Ambitious from '../images/SO_AMBITIOUS.jpg'
import AmbitiousV from '../images/SO_AMBITIOUS.mp4'
import HBO from '../images/Comcast_HBO_30.jpg'
import HBOV from '../images/Comcast_HBO_30.mp4'
import '../assets/scss/main.scss'



const SecondPage = () => (
  <Layout>
    <h1>Creative Work</h1>
    <p>Sample projects that I have worked on over the years</p>
    <span>
    <table border="2">
    <tbody>
    <tr>
    <td>
    <div>
      <a href={JimmyReelV}>
      <img src={JimmyReel} width="100%"></img></a>
    </div>
    </td>
    <td>
    <div>
      <a href={medicalreelV}>
      <img src={medicalreel} width="100%"></img></a>
    </div>
    </td>
    <td>
    <div>
      <a href={dreamV}>
      <img src={dream} width="100%"></img></a>
    </div>
    </td>
    </tr>
    <tr>
    <td>
    <div><h4 align="center">History Channel</h4></div>
    </td>
    <td>
    <div><h4 align="center">Elefun and Friends</h4></div>
    </td>
    <td>
    <div><h4 align="center">First Data</h4></div>
    </td>
    </tr>
    <tr>
    <td>
    <div>
      <a href={historyV}>
      <img src={history} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={ElefunV}>
      <img src={Elefun} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={firstdataV}>
      <img src={firstdata} width="100%"></img></a>
      </div>
    </td>
    </tr>
    <tr>
    <td>
    <div><h4 align="center">History Channel</h4></div>
    </td>
    <td>
    <div><h4 align="center">Elefun and Friends</h4></div>
    </td>
    <td>
    <div><h4 align="center">First Data</h4></div>
    </td>
    </tr>
    <tr>
    <td>
    <div>
      <a href={lovesignsV}>
      <img src={lovesigns} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={KreoV}>
      <img src={Kreo} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={cokeV}>
      <img src={coke} width="100%"></img></a>
      </div>
    </td>
    </tr>
    <tr>
    <td>
    <div><h4 align="center">Love Signs</h4></div>
    </td>
    <td>
    <div><h4 align="center">Kreo BattleShip</h4></div>
    </td>
    <td>
    <div><h4 align="center">Coke Pay It Forward</h4></div>
    </td>
    </tr>
    <tr>
    <td>
    <div>
      <a href={nerfV}>
      <img src={nerf} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={verizonV}>
      <img src={verizon} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={IONV}>
      <img src={ION} width="100%"></img></a>
      </div>
    </td>
    </tr>
    <tr>
    <td>
    <div><h4 align="center">Nerf Light It Up</h4></div>
    </td>
    <td>
    <div><h4 align="center">Net Verizon</h4></div>
    </td>
    <td>
    <div><h4 align="center">ION 4</h4></div>
    </td>
    </tr>
    <tr>
    <td>
    <div>
      <a href={NEWBRANDV}>
      <img src={NEWBRAND} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={AmbitiousV}>
      <img src={Ambitious} width="100%"></img></a>
      </div>
    </td>
    <td>
    <div>
      <a href={HBOV}>
      <img src={HBO} width="100%"></img></a>
      </div>
    </td>
    </tr>
    <tr>
    <td>
    <div><h4 align="center">Verizon Brand Module</h4></div>
    </td>
    <td>
    <div><h4 align="center">So Ambitious</h4></div>
    </td>
    <td>
    <div><h4 align="center">Comcast HBO</h4></div>
    </td>
    </tr>
  </tbody>
    </table>
    </span>
    </Layout>
)





export default SecondPage
